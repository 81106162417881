import tw from 'twin.macro';
import { Text } from 'styles';
import { AlertCircleIcon } from './svgs/AlertCircleIcon';
import { BorderButton } from './Button';
import { logout } from 'helpers';
import Divider from './Divider';

const Wrapper = tw.div`
  bg-secondaryCream
  min-h-screen w-full
  flex justify-center items-center
  px-4 pb-20
`;

const Content = tw.div`
  flex flex-col
  justify-center items-center
`;

const IconContainer = tw.p`
  text-failedRed
  w-[31.5px] h-[31.5px]
`;

const Title = tw(Text.LargeBold)`
  text-failedRed text-center
  max-w-sm
  py-7
`;

const Description = tw(Text.Small)`
  text-primaryBlue text-center
  max-w-xs
`;

const Link = tw.a`
  text-primaryPurple text-sm whitespace-nowrap
`;

const LinkUnderline = tw(Link)`
  underline
`;

const ReturnButton = tw(BorderButton)`
  h-11 text-primaryPurple tracking-widest transition duration-300
  hover:text-white hover:bg-primaryPurple
`;

const PHONE = '1300 727 575';
const EMAIL = 'membership@vrc.net.au';

const LoginFailed = () => {
  return (
    <Wrapper>
      <Content>
        <IconContainer>
          <AlertCircleIcon />
        </IconContainer>
        <Title>
          We're currently experiencing difficulties and cannot log you in at
          this time.
        </Title>
        <Description>
          Please try again later, or contact us at{' '}
          <Link href={`tel:${PHONE.split(' ').join('')}`}>{PHONE}</Link> or via
          email at{' '}
          <LinkUnderline href={`mailto:${EMAIL}`}>{EMAIL}</LinkUnderline>
        </Description>
        <Divider marginVertical="0.5rem" bgColor="transparent" />
        <Description>We apologise for any inconvenience.</Description>
        <Divider marginVertical="1.5rem" bgColor="transparent" />
        <ReturnButton onClick={logout}>RETURN TO LOGIN</ReturnButton>
      </Content>
    </Wrapper>
  );
};

export default LoginFailed;
